var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('new-package')],1)]),_c('v-card-title',[_vm._v(" Packages ")]),_c('v-data-table',{attrs:{"items":_vm.allPackages,"headers":_vm.packageHeaders},scopedSlots:_vm._u([{key:"item.club_products",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[(!item.club_products)?[_vm._v(" No club products are assigned to this package. ")]:[_vm._v(" "+_vm._s(item.club_products.map(function (club_product) { return club_product.name; }).join(','))+" ")]],2)])]}},{key:"item.direct_products",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[(!item.direct_products)?[_vm._v(" No club products are assigned to this package. ")]:[_vm._v(" "+_vm._s(item.direct_products.map(function (club_product) { return club_product.name; }).join(','))+" ")]],2)])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.viewPackage(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.removeProduct(item._id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}},{key:"item.badge",fn:function(ref){
var item = ref.item;
return [(item.badge === 'Active')?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(item.badge)+" ")],1)]:[_c('v-chip',{attrs:{"color":"danger","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-close ")]),_vm._v(" "+_vm._s(item.badge)+" ")],1)]]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }