<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <new-package />
          </div>
        </v-card-title>
        <v-card-title>
          Packages
        </v-card-title>
        <v-data-table
          :items="allPackages"
          :headers="packageHeaders"
        >
          <template v-slot:item.club_products="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                <template v-if="!item.club_products">
                  No club products are assigned to this package.
                </template>
                <template v-else>
                  {{ item.club_products.map(club_product => club_product.name).join(',') }}
                </template>
              </p>
            </div>
          </template>
          <template v-slot:item.direct_products="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                <template v-if="!item.direct_products">
                  No club products are assigned to this package.
                </template>
                <template v-else>
                  {{ item.direct_products.map(club_product => club_product.name).join(',') }}
                </template>
              </p>
            </div>
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="viewPackage(item)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="danger"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="removeProduct(item._id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.badge="{item}">
            <template v-if="item.badge === 'Active'">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  left
                >
                  mdi-check
                </v-icon>
                {{ item.badge }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  left
                >
                  mdi-close
                </v-icon>
                {{ item.badge }}
              </v-chip>
            </template>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import NewPackage from './NewPackage.vue'

  export default {
    name: 'Packages',
    components: {
      NewPackage,
    },
    data: () => ({
      packageHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Club Products',
          value: 'club_products',
        },
        {
          text: 'Direct Products',
          value: 'direct_products',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Actions',
          value: 'action',
        },
      ],
    }),
    created () {
      this.getPackages()
    },
    computed: {
      ...mapGetters(['allPackages']),
    },
    methods: {
      ...mapActions(['getPackages', 'removeProduct']),
      viewPackage (pkg) {
        this.$router.push(`/admin/packages/${pkg._id}`)
      },
    },
  }
</script>

<style>

</style>
