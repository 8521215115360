<template>
  <v-dialog v-model="newPackageDialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="rounded ma-2" tile color="primary" v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus </v-icon>
        New Package
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">New Package</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newPackage.name"
                label="Package name"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newPackage.description"
                label="Package description"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="newPackage.club_products"
                label="Select club products"
                multiple
                :items="clubProducts"
                item-text="name"
                item-value="_id"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="newPackage.direct_products"
                label="Select Direct Products"
                multiple
                :items="allProducts"
                item-text="name"
                item-value="_id"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="newPackage.club"
                label="Select Club"
                multiple
                :items="allClubs"
                item-text="name"
                item-value="_id"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="newPackage.type"
                label="Select Type"
                :items="packageTypes"
                item-text="name"
                item-value="_id"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="newPackageDialog = false">
          Close
        </v-btn>
        <v-btn color="primary" @click="savePackage"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewPackageVue",
  data: () => ({
    packageTypes: [
      { name: "Public", _id: "PUBLIC" },
      { name: "Private", _id: "PRIVATE" },
    ],
    newPackageDialog: false,
    newPackage: {},
  }),
  created() {
    this.getClubProducts();
    this.getAllProducts();
    this.getAllClubs();
  },
  computed: {
    ...mapGetters(["clubProducts", "allProducts", "allClubs"]),
  },
  methods: {
    ...mapActions([
      "getClubProducts",
      "getAllProducts",
      "createPackage",
      "getAllClubs",
    ]),
    savePackage() {
      this.newPackageDialog = false;
      this.createPackage(this.newPackage);
    },
  },
};
</script>

<style>
</style>
